import { Props, fillColors } from '.';

export const IconPlus = ({
  width = 15,
  height = 15,
  color = 'nad-alps-night',
  className = '',
  ...otherProps
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
      {...otherProps}
    >
      <g clipPath="url(#clip0_12589_9357)">
        <path d="M15 6H9V0H6V6H0V9H6V15H9V9H15V6Z" fill="#003B5C" />
      </g>
      <defs>
        <clipPath id="clip0_12589_9357">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
